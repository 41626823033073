/*
const accordionContainer = document.querySelector('.our-specialties__accordion');
accordionContainer.addEventListener('click', (event) => {
  const clickedItem = event.target.closest('.our-specialties__accordion-item');

  if (clickedItem) {
    accordionContainer.querySelectorAll('.our-specialties__accordion-item').forEach(item => {
      item.classList.remove('active');
    });

    clickedItem.classList.add('active');
  }
});
*/
