/*Splitting();*/
import Parallax from 'parallax-js';
import { log } from 'sharp/lib/libvips.js';

if (document.querySelector('[data-parallax-el]') && window.innerWidth >= 1025) {
  const parallaxContainer = document.querySelectorAll('[data-parallax-el]');
  parallaxContainer.forEach((elem) => {
    let parallaxInstance = new Parallax(elem);
    parallaxInstance.enable();
  });
}

if (document.querySelector('[data-duplicate]')) {
  const originalArr = document.querySelectorAll('[data-original]');
  const duplicateArr = document.querySelectorAll('[data-duplicate]');

  originalArr.forEach((originalElement) => {
    const originalValue = originalElement.dataset.original;
    duplicateArr.forEach((duplicateElement) => {
      const duplicateValue = duplicateElement.dataset.duplicate;
      if (originalValue === duplicateValue) {
        duplicateElement.textContent = originalElement.textContent;
      }
    });
  });
}

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    if (item.hasAttribute('data-splitting-char') === true) {
      const splitting = Splitting({
        target: item,
      });
    } else {
      const splitting = Splitting({
        target: item,
        by: 'words',
      });
    }
  });
}

window.initTilt = function initTilt() {
  if (document.querySelector('[data-tilt-init]') && window.innerWidth > 1024) {
    const tiltInitItemsArr = document.querySelectorAll('[data-tilt-init]');
    tiltInitItemsArr.forEach((item) => {
      VanillaTilt.init(item, {
        /*max: 7,*/
        speed: 500,
      });
    });
  }
};
initTilt();

if (document.querySelector('.anime-motion-animation')) {
  const animeMotionAnimElArr = document.querySelectorAll(
    '.anime-motion-animation'
  );
  animeMotionAnimElArr.forEach((animeMotionAnimEl) => {
    const defaultSpeed = 3000;
    const originalSpeed =
      Number(parseFloat(animeMotionAnimEl.getAttribute('data-anime-speed'))) ||
      defaultSpeed;

    const petaAnimation = anime({
      targets: animeMotionAnimEl.querySelector('svg path'),
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: originalSpeed,
      delay: function (el, i) {
        return i * 1;
      },
      direction: 'normal',
      loop: false,
    });
    petaAnimation.pause();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              petaAnimation.play();
              animeMotionAnimEl.classList.add('animated');
            }, 600);
            observer.unobserve(animeMotionAnimEl);
          }
        });
      },
      { threshold: 0, rootMargin: '0% 0% -10% 0%' }
    );

    observer.observe(animeMotionAnimEl);
  });
}

/*import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const gsapViewArr = document.querySelectorAll('[data-gsap-view]');
if (gsapViewArr.length > 0) {
  gsapViewArr.forEach((el, i) => {
    gsapViewAnimation(el, el, 25, 0, 0, 0);
  });

  function gsapViewAnimation(triggerEl, animEl, minY, minX, maxY, maxX) {
    if (ScrollTrigger.isTouch !== 1) {
      gsap.fromTo(
        animEl,
        {
          translateY: `${minY}%`,
          translateX: `${minX}%`,
          /!*opacity: 0,*!/
        },
        {
          translateY: `${maxY}%`,
          translateX: `${maxX}%`,
          /!*opacity: 1,*!/
          scrollTrigger: {
            trigger: triggerEl,
            end: 'top',
            scrub: true,
          },
        }
      );
    } else {
      /!*animEl.removeAttribute('data-scroll-bg');*!/
      /!*animEl.setAttribute('data-view', 'fade-t');*!/
    }
  }
}*/

/*if (document.getElementsByClassName('aura-card') && window.innerWidth > 551) {
  const page = document.querySelector('#site');
  const header = document.querySelector('header');
  const headerBurger = document.querySelector('.header .burger');
  const auraContainers = document.querySelectorAll('.aura-container');

  if (window.innerWidth > 1025) {
    auraContainers.forEach((container) => {
      const auraCursor = container.querySelector('.cursor-aura');
      const auraCursorWidth = auraCursor.offsetWidth;
      const auraCursorHeight = auraCursor.offsetHeight;

      const initLeft = parseFloat(getComputedStyle(auraCursor).left);
      const initTop = parseFloat(getComputedStyle(auraCursor).top);

      function cursorAuraLogic(e) {
        auraCursor.classList.add('active');
        const rect = container.getBoundingClientRect();
        let x = e.clientX - rect.left - auraCursorWidth / 1.85;
        let y = e.clientY - rect.top - auraCursorHeight / 1.95;

        const currentLeft = parseFloat(getComputedStyle(auraCursor).left);
        const currentTop = parseFloat(getComputedStyle(auraCursor).top);

        x -= currentLeft;
        y -= currentTop;

        requestAnimationFrame(() => {
          auraCursor.style.left = 0;
          auraCursor.style.top = 0;

          auraCursor.style.transform = `translate(${x}px, ${y}px)`;
        });
      }

      function cursorAuraLeaveLogic() {
        auraCursor.classList.remove('active');
        auraCursor.style.left = `${initLeft}px`;
        auraCursor.style.top = `${initTop}px`;
        auraCursor.style.transform = `translate(0, 0)`;
      }

      container.addEventListener('mousemove', (e) => {
        auraCursor.classList.add('is-init');
        cursorAuraLogic(e);
      });

      container.addEventListener('mouseleave', (event) => {
        const nextElement = document.elementFromPoint(
          event.clientX,
          event.clientY
        );

        if (header.contains(nextElement)) {
          return;
        }
        cursorAuraLeaveLogic();
      });

      if (header) {
        header.addEventListener('mousemove', (e) => {
          if (!header.classList.contains('open-menu')) {
            auraCursor.classList.add('is-init');
            cursorAuraLogic(e);
          }
        });

        header.addEventListener('mouseleave', (event) => {
          const nextElement = document.elementFromPoint(
            event.clientX,
            event.clientY
          );

          console.log(nextElement);

          if (container.contains(nextElement)) {
            console.log(123);
            return;
          }
          cursorAuraLeaveLogic();
        });

        headerBurger.addEventListener('click', () => {
          cursorAuraLeaveLogic();
        });
      }

      /!*window.addEventListener('DOMContentLoaded', (event) => {
        const isCursorOverElement = isCursorOverElementOnLoad(container);
        console.log(
          'Курсор на элементе при загрузке страницы:',
          isCursorOverElement
        );
        cursorAuraLogic(event);
      });*!/
    });

    /!*function isCursorOverElementOnLoad(element) {
      const rect = element.getBoundingClientRect();
      const mouseX = window.pageXOffset + rect.left;
      const mouseY = window.pageYOffset + rect.top;
      console.log(mouseX);
      console.log(mouseY);
      return (
        mouseX >= 0 &&
        mouseX <= window.innerWidth &&
        mouseY >= 0 &&
        mouseY <= window.innerHeight
      );
    }*!/
  }
}*/
