const sectorsSection = document.querySelector('.sectors-section');
if (sectorsSection) {
  const sectorsTabsArr = document.querySelectorAll('.sectors-section__tab');
  const sectorsImagesArr = document.querySelectorAll('.sectors-section__image');
  const sectorsContentsArr = document.querySelectorAll(
    '.sectors-section__content'
  );

  sectorsTabsArr.forEach((tab, i) => {
    tab.addEventListener('click', () => {
      for (let j = 0; j < sectorsTabsArr.length; j++) {
        sectorsTabsArr[j].classList.remove('active');
        sectorsImagesArr[j].classList.remove('active');
        sectorsContentsArr[j].classList.remove('active');
      }

      tab.classList.add('active');
      sectorsImagesArr[i].classList.add('active');
      sectorsContentsArr[i].classList.add('active');
    });
  });
}
