window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

const choicesDOM = document.querySelector(['[data-choices]']);
if (choicesDOM) {
  const choicesArr = document.querySelectorAll(['[data-choices]']);
  for (let i = 0; i < choicesArr.length; i++) {
    const parentContainer = choicesArr[i].parentNode;
    /*const list = parentContainer.querySelector(".default-select__list");*/

    const choices = new Choices(choicesArr[i], {
      searchEnabled: false,
      itemSelectText: '',
      placeholder: true,
      searchPlaceholderValue: 'Search',
      classNames: {
        flippedState: '',
      },
    });

    const choicesMultipleElement = parentContainer.querySelector(
      ".choices[data-type='select-multiple']"
    );

    if (choicesMultipleElement) {
      choicesMultipleElement.addEventListener('click', () => {
        if (parentContainer.querySelector('.is-open')) {
          choices.hideDropdown();
        }
      });
    }

    /* New multiselect logic */
    if (
      choicesArr[i].multiple &&
      choicesArr[i].hasAttribute('data-multiple-list-logic')
    ) {
      let optionName = null;
      let optionValue = null;

      const multiplePlaceholder = parentContainer.querySelector(
        '.choices__list--multiple'
      );

      const list = document.createElement('ul');
      list.className = 'meta-select__list';
      parentContainer.appendChild(list);

      function createListItem(optionName, optionValue) {
        const listItem = document.createElement('li');
        listItem.setAttribute('data-val', optionValue);
        listItem.innerHTML = `${optionName}`;
        listItem.classList.add('meta-select__list-item');
        list.appendChild(listItem);

        listItem.addEventListener('click', () => {
          handleListItemClick(listItem);
        });
      }
      function handleSelectedOptions() {
        list.innerHTML = '';

        const selectedOptions = Array.from(choicesArr[i].selectedOptions);

        if (selectedOptions.length >= 1) {
          list.classList.add('is-visible');
        } else {
          list.classList.remove('is-visible');
        }

        if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
          choices.setChoiceByValue('');
        }

        selectedOptions.forEach(function (option) {
          optionName = option.textContent;
          optionValue = option.value;
          if (optionName !== 'Select') {
            createListItem(optionName, optionValue);
          }
        });

        const listArr = list.querySelectorAll('.meta-select__list-item');
        if (listArr.length === 1) {
          multiplePlaceholder.textContent = optionName;
        } else if (listArr.length >= 2) {
          multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
        } else {
          multiplePlaceholder.textContent = 'Select';
        }
      }

      function handleListItemClick(listItem) {
        const optionValue = listItem.getAttribute('data-val');

        choices.removeActiveItemsByValue(optionValue);
        handleSelectedOptions();
      }

      handleSelectedOptions();

      choicesArr[i].addEventListener('change', function () {
        handleSelectedOptions();
      });

      list.addEventListener('click', function (event) {
        const liElement = event.target.closest('.meta-select__list-item');
        if (liElement) {
          handleListItemClick(liElement);
        }
      });
    }
  }
}

function formatInput(inputEl, maxValue) {
  let sanitizedValue = inputEl.value.replace(/[^\d]/g, '');

  if (sanitizedValue === '') {
    inputEl.value = '';
    return;
  }

  const numericValue = Number.isNaN(parseInt(sanitizedValue))
    ? 0
    : parseInt(sanitizedValue);

  sanitizedValue = Math.min(numericValue, maxValue).toString();
  const formattedValue = sanitizedValue.replace(
    /(\d)(?=(\d{3})+(\D|$))/g,
    '$1 '
  );
  inputEl.value = '£' + formattedValue;
}

if (document.querySelector('.salary-input')) {
  const salaryInputsArr = document.querySelectorAll('.salary-input');
  salaryInputsArr.forEach((input) => {
    formatInput(input, 999999);
    input.addEventListener('input', () => {
      formatInput(input, 999999);
    });
  });
}

window.clearSalaryInput = function clearSalaryInput(id) {
  const salaryInputEl = document.querySelector(`#${id}`);
  salaryInputEl.value = '';
  formatInput(salaryInputEl, 999999);
};

function customSelectsLogic() {
  const defaultCustomSelect = document.getElementsByClassName(
    'default-custom-select'
  )[0];
  if (defaultCustomSelect) {
    const defaultCustomSelectArr = document.querySelectorAll(
      '.default-custom-select'
    );

    defaultCustomSelectArr.forEach((customSelect, i) => {
      const zIndexValue = defaultCustomSelectArr.length - i;
      customSelect.style.zIndex = zIndexValue + 10;

      const customSelectPlaceholder = customSelect.querySelector(
        '.default-custom-select__placeholder'
      );
      const customSelectList = customSelect.querySelector(
        '.default-custom-select__list'
      );
      const customSelectPlaceholderTextContent = customSelect.querySelector(
        '.default-custom-select__placeholder'
      ).textContent;
      customSelectPlaceholder.setAttribute(
        'data-original-placeholder',
        customSelectPlaceholderTextContent
      );

      customSelect.addEventListener('click', (e) => {
        if (!customSelect.classList.contains('active')) {
          customSelect.classList.add('active');
          console.log(customSelect);
        } else {
          if (!e.target.classList.contains('default-custom-select__list')) {
            customSelect.classList.remove('active');
          }
        }
      });

      document.addEventListener('click', (event) => {
        if (
          !customSelectList.contains(event.target) &&
          !customSelect.contains(event.target)
        ) {
          customSelect.classList.remove('active');
        }
      });

      const checkboxesSelect = customSelect.querySelectorAll(
        'input[type="checkbox"]'
      );

      function placeholderCheck() {
        const checkedCount = customSelect.querySelectorAll(
          'input[type="checkbox"]:checked'
        ).length;
        console.log(
          customSelect.querySelectorAll('input[type="checkbox"]:checked')
        );

        if (checkedCount === 1) {
          const checkedItem = customSelect.querySelector(
            'input[type="checkbox"]:checked'
          );
          customSelectPlaceholder.textContent =
            checkedItem.nextElementSibling.nextElementSibling.textContent;
        } else {
          customSelectPlaceholder.textContent =
            checkedCount > 0
              ? `${checkedCount} items selected`
              : customSelectPlaceholderTextContent;
        }
      }

      checkboxesSelect.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          placeholderCheck();
        });
      });

      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (let j = 0; j < checkboxes.length; j++) {
        checkboxes[j].addEventListener('change', () => {
          placeholderCheck();
        });
      }

      placeholderCheck();
    });
  }
}
customSelectsLogic();

window.checkAllCheckboxes = function checkAllCheckboxes() {
  const customSelects = document.querySelectorAll('.default-custom-select');

  customSelects.forEach((customSelect) => {
    const customSelectPlaceholder = customSelect.querySelector(
      '.default-custom-select__placeholder'
    );

    function placeholderCheck() {
      const checkedCount = customSelect.querySelectorAll(
        'input[type="checkbox"]:checked'
      ).length;
      if (checkedCount === 1) {
        const checkedItem = customSelect.querySelector(
          'input[type="checkbox"]:checked'
        );
        customSelectPlaceholder.textContent =
          checkedItem.nextElementSibling.nextElementSibling.textContent;
      } else {
        customSelectPlaceholder.textContent =
          checkedCount > 0
            ? `${checkedCount} items selected`
            : customSelectPlaceholder.getAttribute('data-original-placeholder');
      }
    }

    placeholderCheck();
  });
};
