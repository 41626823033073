export function swiperMarquee(swiper, options = {}) {
  let defaultOptions = {
    speed: 1,
    hrefClass: '',
    changeActiveClass: false,
    activeSlideLengthToggle: 1,
  };

  let mergeOptions = Object.assign(defaultOptions, options);

  let FLAG_FOR_ENTER = false;

  swiper.params.allowTouchMove = false;
  swiper.params.noSwiping = true;
  swiper.params.noSwipingClass = 'swiper-slide';
  swiper.params.watchSlidesVisibility = true;
  swiper.params.freeMode = true;
  swiper.update();

  let startProgress = swiper.progress;
  let startTranslate = swiper.translate;
  const swiperParams = swiper.params;
  const swiperWrapper = swiper.wrapperEl;

  if (
    mergeOptions.hrefClass !== '' &&
    typeof mergeOptions.hrefClass === 'string'
  ) {
    swiperWrapper.querySelectorAll(mergeOptions.hrefClass).forEach((elem) => {
      elem.addEventListener('click', function () {
        window.location = elem.getAttribute('data-src');
      });
    });
  }

  swiperWrapper.addEventListener('mouseenter', () => {
    FLAG_FOR_ENTER = true;
  });

  swiperWrapper.addEventListener('mouseleave', () => {
    FLAG_FOR_ENTER = false;
  });

  let startTimeFlip = new Date().getTime();
  let lastTime = 0;
  let FlagOnSwitchActiveSlide = false;

  let activeIndex = Math.floor(
    swiperParams.loop ? swiper.slides.length / 3 : swiper.slides.length
  );
  const swiperAmount = activeIndex;

  function changeActiveSlide(startTranslateValue, translateValue) {
    let swiperSlide = swiper.slides[activeIndex];

    let swiperSlideWidth = swiperSlide.swiperSlideSize;

    if (
      startTranslateValue - translateValue >=
        swiperSlideWidth * mergeOptions.activeSlideLengthToggle &&
      !FlagOnSwitchActiveSlide
    ) {
      FlagOnSwitchActiveSlide = true;
      startTranslateForActiveSwitch -= swiperSlideWidth;
    }

    if (FlagOnSwitchActiveSlide) {
      let ariaLabelNumberOLD = (activeIndex % swiperAmount) + 1;

      activeIndex += 1;

      let ariaLabelNumber = (activeIndex % swiperAmount) + 1;

      swiper.slides.forEach((elem) => {
        let ariaLabelFormat = Number(
          elem.getAttribute('aria-label').replace(` / ${swiperAmount}`, '')
        );

        if (ariaLabelNumber === ariaLabelFormat) {
          if (elem.classList.contains('swiper-slide-duplicate')) {
            elem.classList.add('swiper-slide-duplicate-active');
          } else {
            elem.classList.add('swiper-slide-active');
          }
        }

        if (ariaLabelNumberOLD === ariaLabelFormat) {
          if (elem.classList.contains('swiper-slide-duplicate')) {
            elem.classList.remove('swiper-slide-duplicate-active');
          } else {
            elem.classList.remove('swiper-slide-active');
          }
        }
      });

      FlagOnSwitchActiveSlide = false;
    }
  }

  let startTranslateForActiveSwitch = startTranslate;

  function SwiperAutoplaySmooth(timeStamp) {
    let timeInSecond = timeStamp / 1000;

    if (timeInSecond - lastTime >= 0.001) {
      lastTime = timeInSecond;

      if (!FLAG_FOR_ENTER) {
        swiper.translateTo(swiper.translate - mergeOptions.speed, 0.01);
        swiper.updateProgress();

        if (mergeOptions.changeActiveClass) {
          changeActiveSlide(
            startTranslateForActiveSwitch,
            swiper.translate,
            swiper.activeIndex
          );
        }

        if (swiper.progress - startProgress >= startProgress) {
          swiper.translateTo(startTranslate, 1);
          swiper.updateProgress();

          startTranslateForActiveSwitch = startTranslate;
        }
      }
    }

    requestAnimationFrame(SwiperAutoplaySmooth);
  }

  SwiperAutoplaySmooth();
}
