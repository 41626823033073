const insightsList = document.querySelector('.insights-section__list');

if (insightsList) {
  function updateLineClampClass() {
    const items = insightsList.querySelectorAll('.insights-section__content');

    if (window.innerWidth < 540) {
      if (items[0]) {
        items[0].classList.remove('line-clamp-1');
        items[0].classList.add('line-clamp-4');
      }
    } else {
      if (items[0]) {
        items[0].classList.remove('line-clamp-4');
        items[0].classList.add('line-clamp-1');
      }
    }
  }

  window.addEventListener('resize', updateLineClampClass);
  updateLineClampClass();
}