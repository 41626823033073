const stickyButtonsEl = document.querySelector('.sticky-buttons');

if (stickyButtonsEl) {
  const footer = document.querySelector('footer');

  window.addEventListener('scroll', () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const documentHeight = document.documentElement.offsetHeight;

    const threshold = documentHeight * 0.1;

    if (scrollPosition >= documentHeight - threshold) {
      stickyButtonsEl.classList.add('is-hidden');
    } else {
      stickyButtonsEl.classList.remove('is-hidden');
    }
  });

  if (footer) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            stickyButtonsEl.classList.add('is-hidden');
          } else {
            stickyButtonsEl.classList.remove('is-hidden');
          }
        });
      },
      { threshold: 0, rootMargin: '0% 0% 10% 0%' }
    );

    observer.observe(footer);
  }
}
