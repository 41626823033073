const accordionItems = document.querySelectorAll('.our-specialties__accordion-item');
const specialtyImages = document.querySelectorAll('.our-specialties__image');

accordionItems.forEach((item, index) => {
  item.addEventListener('click', () => {

    accordionItems.forEach(item => item.classList.remove('active'));
    specialtyImages.forEach(image => image.classList.remove('active'));

    item.classList.add('active');
    specialtyImages[index].classList.add('active');

    if (window.innerWidth <= 768) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  });
});