const sidebar = document.querySelector('.job-search');

if (sidebar) {
  const sidebarBar = sidebar.querySelector('.job-search__aside');
  const sidebarBtn = sidebar.querySelector('#sidebarBtn');
  const sidebarContainer = sidebar.querySelector('.job-search__right');
  const sidebarCloseBtn = sidebar.querySelector('.job-search__aside-close');

  sidebarCloseBtn.addEventListener('click', () => {
    sidebar.classList.remove('is-open-sidebar');
  })

  sidebarBtn.addEventListener('click', () => {
    openSidebarFunc();
  })

  sidebarContainer.addEventListener('click', () => {
    sidebar.classList.remove('is-open-menu');
  })

  function openSidebarFunc() {
    if (!sidebar.classList.contains('is-open-sidebar')) {
      sidebar.classList.add('is-open-sidebar');
    } else {
      sidebar.classList.remove('is-open-sidebar');
    }
  }
}